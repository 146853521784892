// This is the template for a single sign
import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/shared/layout";
import { Seo } from "../components/shared/seo";
import { WorkGridItem } from "../components/shared/workGridItem";
import { gridStyle } from "../utils/gridStyles";
import { BackBtn } from "../components/shared/backBtn";
import { MediumsNavbar } from "../components/mediums/mediumsNavbar";
// ------------> Sign (single) page <------------ //
const MediumPageTemplate = (props) => {
  // --->  Data <--- //
  const medium = props.data.strapiMedium;
  const { mediumType } = props.data.strapiMedium;
  // const nextMedium = props.pageContext.nextMediumSlug;
  // const prevSign = props.pageContext.previousSignSlug;

  return (
    <Layout location={props.location}>
      <MediumsNavbar medium={mediumType} />
      {/* <div className="text-lg">{mediumType}</div> */}
      <div className={`${gridStyle}`}>
        {medium.works.map((work, index) => {
          return (
            <WorkGridItem
              key={index}
              work={work}
              classes={""}
              isMedium={false}
              location={props.location}
            />
          );
        })}
        {/* ---> Back  Next buttons <--- */}
      </div>
      <BackBtn classes={"my-xl"} />

      {/* <div className={`text-amber-300`}>
        <Link className={""} to={`/medium/${nextMedium}`}>
          Next
        </Link>
      </div> */}
    </Layout>
  );
};

export const query = graphql`
  query ($mediumType: String) {
    site {
      siteMetadata {
        title
      }
    }
    strapiMedium(mediumType: { eq: $mediumType }) {
      mediumType
      works {
        artist {
          firstName
          lastName
          slug
        }
        heroImage {
          imageCaption
          imageAlt
          imageFile {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: NO_CHANGE
                  width: 700
                  #height: 100
                  quality: 100
                  #layout: FULL_WIDTH
                  transformOptions: { fit: COVER }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        year
        title
      }
    }
  }
`;

export default MediumPageTemplate;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Mediums"}
    description={"IlCollection Artists"}
    keywords={"IlCollection Artists, Art"}
  />
);
